import React, { CSSProperties, useEffect, useState } from "react";
import { Input } from "semantic-ui-react";
import { useIntl } from "react-intl";


type SearchBoxProps = {
  "data-testid"?: string;
  vertical?: any
  label?: any
  style?: any
  toDisplay?: any
  selectedItem?: any;
  setSelectedItem?: any;
  onEvent?: any;
  setProp?: any;

  verbose?: any;
  disabled?: any;
  placeholder?: any;
  fluid?: any;
  searchedItemList?: any;
  icon?: any;
  type?: any;
  searchedItemListWithKey?: any;
  useWithKey?: boolean;
  searchLength?: any;
  searchType?: any;
  inputRef?: any;
  dropdownStyle?: CSSProperties;

  // Provider type #68930
  division?: any;
}

const SearchBox = (props: SearchBoxProps) => {

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    return () => {
      props.onEvent({
        message: "ItemSearch",
        type: props.type,
        params: {action: "clear"}}
      );
    }
  }, [])

  return(
    <div
      data-testid={props['data-testid'] || "SearchBox-Wrapper"}
      style={props.vertical ?
        {display: "flex", flexDirection: "column", alignItems: "flex-start", flex: 1}
        :{display: "flex", justifyContent: "flex-start", alignItems: "center", flex: 1}}>
      <div style={{marginRight: props.label !== "" ? "10px" : "0px"}}>{props.label}</div>
      <div
        data-testid="SearchBox-Container"
        style={{ position: "relative", ...(props.style || {})}}>
        <Input
          data-testid="SearchBox-Input"
          ref={props.inputRef}
          value={props.selectedItem ? props.toDisplay(props.selectedItem) : searchText}
          onChange={(e: any) => {
            setSearchText(e.target.value);
            props.setSelectedItem(null);
            if (e.target.value.length >= (props.searchLength || 3)) {
              let searchParams:any = {type: props.type, searchText: e.target.value, verbose: props?.verbose, ...(props?.division && {division: props?.division})}
              if (props.searchType !== "") {
                searchParams["searchType"] = props.searchType
              }
              console.log("searchParams",searchParams);
              props.onEvent({
                message: "ItemSearch",
                params: searchParams});
            }
          }}

          {...(props.icon && { icon: props.icon})}

          onBlur={(e: any) => {
            setTimeout(() => {
              setSearchText("");
            }, 300);
          }}
          onFocus={(e: any) => {
            console.log("onFocus check props.selectedItem", props.selectedItem)
            if (props.selectedItem) {
              console.log("onFocus clear serachText and setto null")
              setSearchText("");
              props.setSelectedItem(null);
            }
          }}
          disabled={props.disabled}
          placeholder={props.placeholder}
          fluid={props.fluid}
        />
        {!props.useWithKey && searchText.length >= (props.searchLength || 3) && props.searchedItemList
        && props.searchedItemList.length > 0 && (props.selectedItem === null) &&
        <div
          data-testid="SearchBox-Dropdown"
          style={{
            position: "absolute", zIndex: 100, top: 40, left: 0, width: "15vw",
            maxHeight: "30vh", backgroundColor: "white", overflowY: "scroll",
            padding: "5px", border: "solid #cccccc 1px",
            ...props.dropdownStyle
          }}>
          {props.searchedItemList.map((item: any, index: number) => (
            <div data-testid={`SearchBox-Dropdown-Item-${index}`} key={index} style={{cursor: "pointer", padding: "10px"}}
              onClick={(e: any) => {
                setSearchText("");
                props.setSelectedItem(item)
                props.onEvent({
                  message: "ItemSearch",
                  params: {action: "clear", type: props.type}});
              }}>
              {props.toDisplay(item)}
            </div>
          ))}
        </div>}

        {props.useWithKey && searchText.length >= props.searchLength && props.searchedItemListWithKey
        && props.searchedItemList?.[props.type]?.length > 0 && props.selectedItem === null &&
        <div
          data-testid="SearchBox-Dropdown"
          style={{
            position: "absolute", zIndex: 100, top: 40, left: 0, width: "15vw",
            maxHeight: "30vh", backgroundColor: "white", overflowY: "scroll",
            padding: "5px", border: "solid #cccccc 1px",
            ...props.dropdownStyle
          }}>
          {props.searchedItemList?.[props.type]?.map((item: any, index: number) => (
            <div data-testid={`SearchBox-Dropdown-Item-${index}`} key={index} style={{cursor: "pointer", padding: "10px"}}
              onClick={(e: any) => {
                setSearchText("");
                props.setSelectedItem(item)
                props.onEvent({
                  message: "ItemSearch",
                  params: {action: "clear", type: props.type}});
              }}>
              {props.toDisplay(item)}
            </div>
          ))}
        </div>}
      </div>
    </div>
  )
}

export default SearchBox;