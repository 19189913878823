import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  MouseEvent,
  CSSProperties,
  ReactElement,
  SyntheticEvent,
} from "react";
import { Button, ButtonProps, Icon, SemanticICONS } from "semantic-ui-react";
import { useIntl } from "react-intl";

type ButtonLoadCheckProps<T = undefined> = {
  // function
  setProp?: (key: any, value: any, callback?: () => any) => any;
  onClick?: BLClickHandler<T>;
  // data
  paramKey?: string;
  buttonLoadCheck?: string;
  // config
  color?: ButtonProps["color"];
  disabled?: boolean;
  fluid?: boolean;
  name?: T;
  size?: ButtonProps["size"];
  style?: CSSProperties;
  iconStyle?: CSSProperties;
  title?: string | ReactElement;
  basic?: boolean;
  className?: string;
  icon?: SemanticICONS;
  iconRight?: SemanticICONS;
  id?: string;
};

const ButtonLoadCheckInitial: Omit<ButtonLoadCheckProps, "setProp"> = {
  // data
  paramKey: "",
  buttonLoadCheck: "",
  // config
  disabled: false,
  fluid: false,
  size: "small",
  style: {},
  iconStyle: {},
  title: "",
  basic: false,
  className: "",
  id: "",
};

export type BLClickHandler<T = undefined> = (
  e: SyntheticEvent,
  data: { name: T } & ButtonProps
) => void;

const ButtonLoadCheck = (props: ButtonLoadCheckProps) => {
  const intl = useIntl();
  const [iconName, setIconName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonColor, setButtonColor] = useState<ButtonProps["color"]>();

  const timeoutRef = useRef<any>(0);

  const handleReset = useCallback((timeout = 2000) => {
    timeoutRef.current = setTimeout(() => {
      setIconName(null);
      setButtonColor(undefined);

      props.setProp?.(`buttonLoadCheck.${props.paramKey}`, "");
    }, timeout);
  }, [props.paramKey]);

  useEffect(() => {
    if (props.buttonLoadCheck === "LOADING") {
      setLoading(true);
      setIconName(null);

      clearTimeout(timeoutRef.current);
    } else if (props.buttonLoadCheck === "SUCCESS") {
      setLoading(false);
      setIconName("check");

      handleReset();
    } else if (props.buttonLoadCheck === "ERROR") {
      setLoading(false);
      setIconName("cancel");
      setButtonColor("red");

      handleReset();
    } else if (!props.buttonLoadCheck && loading) {
      if (props.buttonLoadCheck !== "") {
        setLoading(false);
      }

      handleReset();
    }
  }, [handleReset, loading, props.buttonLoadCheck]);

  useEffect(
    () => () => {
      handleReset(0);
    },
    []
  );

  // Handler
  const handleClick = (e: MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
    if (props.buttonLoadCheck) {
      console.log("Button is in loading state or disabled");
    } else {
      props.onClick?.(e, { ...data, name: props.name });
    }
  };

  return (
    <Button
      {...("id" in props && { id: props.id })}
      {...("className" in props && { className: props.className })}
      color={buttonColor || props.color}
      {...("disabled" in props && { disabled: props.disabled })}
      {...("fluid" in props && { fluid: props.fluid })}
      loading={loading}
      {...("name" in props && { name: props.name })}
      onClick={handleClick}
      {...("size" in props && { size: props.size })}
      {...("style" in props && {
        style: {
          ...props.style,
          ...(props.style?.border && {
            border: buttonColor === "red" ? "1px solid #db2828" : props.style.border,
          }),
          ...(props.style?.color && {
            color: buttonColor === "red" ? "#db2828" : props.style.color,
          }),
        },
      })}
      {...("basic" in props && { basic: props.basic })}
      {...("icon" in props && { icon: props.icon })}
    >
      <div style={{ position: "relative" }}>
        {iconName && (
          <Icon
            id="ButtonLoadCheck-Status"
            name={iconName}
            style={{
              ...(props.iconStyle || {}),
              position: "absolute",
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
              justifyContent: "center",
            }}
          ></Icon>
        )}
        <span style={{ opacity: iconName || loading ? 0 : "" }}>
          {props.icon && (
            <Icon name={props.icon} style={{ marginRight: props.title ? "0.428em" : 0 }} />
          )}
          {props.title}
          {props.iconRight && <Icon name={props.iconRight} style={{ marginLeft: "0.428em" }} />}
        </span>
      </div>
    </Button>
  );
};

ButtonLoadCheck.defaultProps = ButtonLoadCheckInitial;

ButtonLoadCheck.displayName = "ButtonLoadCheck";

export default React.memo(ButtonLoadCheck) as <T extends string | undefined = undefined>(
  props: ButtonLoadCheckProps<T>
) => ReactElement;
